<template>
  <div class="share">
      <van-overlay :show="show" @click="show = false">
        <van-loading type="spinner" vertical class="loading"/>
      </van-overlay>
    <div class="box">
       <div class="img-box">
        <img :src="imgUrl"/>
       </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Share',
  data(){
    return {
      show:false,
      imgUrl : "http://img.lchuning.com/img/share.jpg"
    }
  },
  created(){
    this.getShareImg();
  },
  methods:{
    getShareImg(){
        this.show = true;
        this.axios.get('/app/share/share.jpg',{
        }).then((response) => {
            this.imgUrl = response;
            this.show = false;
          }).catch(function (error) {
              console.log(error);
          });
    }
  }
}
</script>
<style scoped>
  .box{
    margin: 50px auto;
    }
    .img-box{
    width: 100%;
    position:relative;
    /* z-index:1; */
    }
    .img-box img{
    position:fixed;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width:100%;
    margin:auto;
    /* z-index: -1; */
    *zoom:1;
    }
    .img-box:before {
    content: "";
    display: inline-block;
    padding-bottom: 100%;
    width: 0.1px; /*必须要有数值，否则无法把高度撑起来*/
    vertical-align: middle;
    }
    .loading{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>
